var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

var site = site || {};

site.template = site.template || {};

(function ($) {
  Drupal.behaviors.skuSelectShadesV1 = {
    attach: function (context) {
      var $template = $('.sku-select-shades-v1', context);

      $template.on('change', 'select.js-sku-select-shades-select', function () {
        // @todo address PR feedback from greg:
        // Why not put the base ID in the mustache attribute? This is an extra step as is.
        var skuId = $(this).val();
        var sku = prodcat.data.getSku(skuId);
        var skuBaseId;

        if (sku) {
          skuBaseId = sku.SKU_BASE_ID;
          $(this).closest('.js-product').trigger('product.skuSelect', [skuBaseId]);
        }
      });
    }
  };
  // Likewise, whenever skuSelect is fired on a product, update the menu:
  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    $('select.js-sku-select-shades-select', this).val(skuBaseId);
    $('select.js-sku-select-shades-select.selectBox-attached', this).selectBox('value', skuBaseId);
    // Price update logic
    // @todo this should use a shared method - update and remove
    var sku = prodcat.data.getSku(skuBaseId);

    if (sku) {
      // Need to wrap sku inside of defaultSku because thats where the template reads
      var content = site.template.get({
        name: 'product_sku_price',
        data: { defaultSku: sku }
      });

      $('.product-sku-price', this).html($(content).html());
    }
  });
})(jQuery);
